.magazines-main-container {
  height: 910px;
  width: 100%;

}

.magazines-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}
.car-photo-magazines {
  height: 200px;
  margin-bottom: 2%;
  margin-top: -2%;
}

.magazines-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 45px;
  margin-top: 5%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.magazines-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.magazines-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.magazines-container {
  padding: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1% 0;
}

.magazines-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: -1%;
}


.magazines-text {
  font-weight: 200;
  line-height: 170%;
  text-align: center;
}

.line {
  margin: 0 auto;
}

.magazines-span-italic {
  font-style: italic;
  font-weight: 520;
}

.magazines-span-title-text {
  font-weight: 400;
  font-size: 19px;
  font-style: italic;
}