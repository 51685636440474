/* 
.convos-with-rudy-page-container {
} */


.convos-with-rudy-main-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
  margin-top: 5%;
}

.rudy-not-dynamic-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.rudy-conversations-link-3 {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.available-titles-rudy-alt-name {
  font-family: "Montserrat", sans-serif;  
  font-size: 28px;
  font-weight: 200;
  margin-bottom: 3%; 
  text-decoration: none;
  color: black;
  text-align: center;
  width: 92%;
}

.buy-now-link {
  margin: 0 auto;
  text-decoration: none;
  border: 2px solid red;
  padding: 6px 4px;
  border-radius: 25px;
  width: 19%;
  text-align: center;
  -webkit-box-shadow: 0px 2px 10px -2px rgba(0,0,0,0.67);
  -moz-box-shadow: 0px 2px 10px -2px rgba(0,0,0,0.67);
  box-shadow: 0px 2px 10px -2px rgba(0,0,0,0.67);
  margin-top: 3%;
}

.buy-now-rudy-link {
  width: 19%;
}

.rudy-last-p {
  margin-bottom: 4%;
}

.rudy-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 17px;
}

.rudy-text-1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 17px;
  margin-top: 2%;
}

.rudy-text-body-1 {
  text-align: justify;
}
.rudy-text-body {
  text-align: justify;
}

.buy-now-div2 {
  width: 30%;
  margin: 0 auto;
}

.buy-now-div3 {
  width: 28%;
  margin-left: 6%;
  margin-bottom: 4%;
  margin-top: 4%;
}


.rudy-price {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-top: 2%;
}


.rudy-author-name {
  margin-bottom: 5%;
  font-weight: 800;
  text-align: center;
  font-size: 23px;
}


.convo-with-rudy-img-alt {
  height: 350px;
}
.convo-with-rudy-2-img-alt {
  height: 380px;
  margin-top: 4%;
}