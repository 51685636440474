
#contactform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

#name-input {
  -webkit-appearance: none;
  border: 1px solid rgb(113,209,189);
  font-size: 13px;
  font-weight: 200;
  font-family: "Montserrat", sans-serif;

}

#email-input {
  -webkit-appearance: none;
  border: 1px solid rgb(113,209,189);
  font-size: 13px;
  font-weight: 200;
  font-family: "Montserrat", sans-serif;

}

#subject-input {
  -webkit-appearance: none;
  border: 1px solid rgb(113,209,189);
  font-size: 13px;
  font-weight: 200;
  font-family: "Montserrat", sans-serif;

}

#message-input {
  -webkit-appearance: none;
  border: 1px solid rgb(113,209,189);
  font-size: 13px;
  font-weight: 200;
  font-family: "Montserrat", sans-serif;

}

#submit-button {
  -webkit-appearance: none;
  background-color:  white;  
  box-shadow: 0px 0px 9px 3px rgba(113,209,189,.7);
  color: black; 
  font-size: 17px;
  font-weight: 200;
  font-family: "Montserrat", sans-serif;
  margin-top: 5%;
  transition: 400ms;
  border: 1px solid rgb(113,209,189);
}

#submit-button:hover {
  -webkit-appearance: none;
  transition: 400ms;
  /* background-color:  #27cff1;   */
  box-shadow: 0px 1px 20px -8px rgba(0,0,0,.0);
}

#submit-button:focus {
  outline: 0;
}
#message-input:focus {
  outline: 0;
}
#subject-input:focus {
  outline: 0;
}
#email-input:focus {
  outline: 0;
}
#name-input:focus {
  outline: 0;
}