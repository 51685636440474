.grants-and-awards-main-container {
  height: 910px;
  width: 100%;

}
.car-photo-vehicle-editions-grants {
  margin-top: 2%;
  height: 110px;
  margin-bottom: 5%;
}

.grants-and-awards-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}


.grants-awards-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 42px;
  margin-top: 5%;
  margin-bottom: 3%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.grants-awards-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}



.grants-and-awards-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.grants-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin-bottom: 5%;
}

.awards-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.g-a-info-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}
/* .grant-title-container {

} */

.awards-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: -1%;
}
.grant-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: -1%;
}

.grant-year {
  margin-top: 7%;
  font-family: "Montserrat", sans-serif;  
  font-weight: 800;
}
.award-year {
  margin-top: 7%;
  font-family: "Montserrat", sans-serif;  
  font-weight: 800;
}
.grant-name {
  font-family: "Montserrat", sans-serif;  
  font-size: 15px;
  font-weight: 200;
  margin-top: -1%; 
}
.award-name {
  font-family: "Montserrat", sans-serif;  
  font-size: 15px;
  font-weight: 200;
  margin-top: -1%; 
}
.grant-giver {
  margin-top: -2%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 200;
}
.award-giver {
  margin-top: -2%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 200;
  font-style: italic;
}

