.body {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* ––––––––– Media Queries –––––––– */

@media only screen and (min-width : 1800px) {
  
	
}

@media only screen and (min-width : 1665px) {
	
}


@media screen and (max-width: 1655px) {
	
}

@media screen and (max-width: 1620px) {

}

@media screen and (max-width: 1595px) {

}

@media screen and (max-width: 1555px) {


}

@media screen and (max-width: 1540px) {


}

@media screen and (max-width: 1478px) {
  .available-titles-rudy-name {
    font-family: "Montserrat", sans-serif;  
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 7%; 
    text-decoration: none;
    color: black;
    text-decoration: underline;
    text-align: center;
  }

}

@media screen and (max-width: 1444px) {

}



@media screen and (max-width: 1421px) {

}


@media screen and (max-width: 1375px) {


}




@media screen and (max-width: 1330px) {

}


@media screen and (max-width: 1266px) {

}


@media screen and (max-width: 1248px) {
	
}


@media screen and (max-width: 1200px) {
  /* Large Desktop */
	
}





@media screen and (max-width: 1155px) {
  /* Large Desktop */
	
  .writing-nav-ul{
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }
}





@media screen and (max-width: 1043px) {
  /* Large Desktop */
	
}







@media screen and (max-width: 975px) {
  /* Large Desktop */
  .about-container-top {
    padding: 2%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 75%;
    margin:0 auto;
    padding-bottom: 6%;
  } 
  .homepage-about-container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
  }
  .homepage-about-container-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
  }
  .about-text-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    width: 100%;
  } 
  .about-about-text {
    width: 100%;
    margin: 0 auto;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 200;
    text-align: center;
  }
  .about-img-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-bottom: 7%;
  }
  .about-car-img-div {
    margin: 0 auto;
    margin-bottom: 7%;
  }
  .car-photo-about {
    height: 140px;
  }
  .about-profile-img {
    height: 200px;
  }
  .about-text-titles {
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-size: 25px;
    margin-bottom: 5%;
    font-style: italic;
    width: 100%;
    text-align: center;
  }


  .resume-img {
    height: 100%;
    margin: 0 auto;
  }

  .resume-page-1-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .resume-page-2-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5%;
  }
}







@media screen and (max-width: 954px) {
	.hally-album-photo-left {
    height: 320px;
    margin-left: 3%;
  }
  .hally-album-photo-right {
    height: 320px;
    margin-left: 3%;
  }
  .annabel-playing-guitar-photo {
    height: 350px;
    margin-left: 3%;
  }
  .hally-wood-info-container {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 5%;
  }
  .music-main-title {
    font-size: 45px;
    margin-top: 5%;
    margin-bottom: 3%;
    margin-left: -1%;
    padding-left: 0;
  }
  .car-photo-music {
    height: 180px;
    margin-bottom: 7%;
    margin-top: 2%;
    margin-left: 1%;
  }

  .benson-gallery-div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 0;
  }

  .benson-images-description {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-style: italic;
    margin-top: 0;
    margin-bottom: 4%;
    text-align: center;
  }
  .benson-gallery-photo-left {
    height: 300px;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 0;
  }
  .benson-gallery-photo-right {
    height: 300px;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 0;
  }
  .train-ride-photo-bottom {
    height: 400px;
  }

  .dennis-img-secondary {
    width: 65%;
  }


}






@media screen and (max-width: 920px) {
	.professional-services-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 50px;
    margin-top: 5%;
    padding-bottom: 1px;
    color: rgba(0, 0, 0, .8);
    font-weight: 400;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }
  #pro-serv-car-photo-1 {
    height: 145px;
    margin-bottom: 2%;
    margin-top: 4%;
  }
  .pro-serv-title-div {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    margin-top: 4%;
    margin-bottom: 2%;
  }
  .pro-serv-nav-ul{
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 0;
    width: 80%;
    margin: 0 auto;
  }
  .pro-serv-nav-li { 
    margin: 2% 4% 2% 4%;
  }

  .rudy-conversations-link-2 {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 3%;
  }
  .typings-img {
    height: 300px;
    margin-right: 0%;
  }
  .typings-2-img {
    height: 300px;
    margin-left: 0%;
    margin-top: 3%;
  }
  .rudy-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-size: 17px;
    text-align: center;
  }

  .resume-info-main-container {
    width: 75%;
    margin: 0 auto;
  }
  
  .convo-with-rudy-img {
    height: 300px;
    margin-right: 0%;
  }
  .convo-with-rudy-2-img {
    height: 300px;
    margin-left: 0%;
    margin-top: 5%;
  }

  .poetics-book-and-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-flow: column;
    width: 80%;
  }

  .poetics-book-img {
    width: 40%;
    margin-bottom: 5%;
  }
}







@media screen and (max-width: 880px) {

	.veh-ed-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 43px;
    margin-top: 5%;
    padding-bottom: 5%;
    color: rgba(0, 0, 0, .8);
    font-weight: 400;
    text-align: center;
    text-shadow: 3px 3px 0px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }
  .car-photo-vehicle-editions-1 {
    height: 120px;
    margin-bottom: 3%;
    margin-top: 2%;
  }
  .veh-ed-nav-ul{
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 0;
    margin: 0 auto;
    width: 70%;
  }
  .veh-ed-nav-li { 
    text-align: center;
    margin: 5% 15% 3% 15%;
  }


	.professional-services-main-title {
    font-size: 43px;
    margin-top: 5%;
    padding-bottom: 1%;
  }
  #pro-serv-car-photo-1 {
    height: 125px;
    margin-bottom: 2%;
    margin-top: 4%;
  }
  .pro-serv-nav-ul{
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding: 0;
    width: 100%;
    margin: 0 auto;
  }
  .pro-serv-nav-li { 
    text-align: center;
    margin: 3% 0% 3% 0%;
  }

  .writing-main-title {
    font-size: 50px;
    margin-top: 6%;
    padding-bottom: 1%;
  }
  .car-photo-writing {
    height: 220px;
    margin-top: -2%;
  }
  .writing-nav-ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }
  .writing-nav-li { 
    text-align: center;
    margin: 3% 0% 3% 0%;
  }


  .news-container-boxes {
    margin: 15% 4% 8% 4%;
    padding: 7%;
    box-shadow: 0px 1px 26px -8px rgba(113, 209, 189, .6);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 70%;
  }

  .awards-title {
    text-align: center;
  }
  .grant-title {
    text-align: center;
  }
  .grant-year {
    text-align: center;
  }
  .award-year {
    text-align: center;
  }
  .grant-name {
    text-align: center;
  }
  .award-name {
    text-align: center;
  }
  .grant-giver {
    text-align: center;
  }
  .award-giver {
    text-align: center;
  }

  .grants-container {
    padding: 2%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
  .awards-container {
    padding: 2%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }



  .clients-car-photo {
    height: 100px;
    margin-bottom: 5%;
    margin-top: 4%;
  }
  .clients-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 38px;
    margin-top: 5%;
    margin-bottom: 3%;
    margin-left: -1%;
    padding-left: 0;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }
}






@media screen and (max-width: 786px) {
  /* tablet */
  .hally-album-photo-left {
    height: 320px;
    margin-left: 3%;
  }
  .hally-album-photo-right {
    height: 320px;
    margin-left: 3%;
  }
  .annabel-playing-guitar-photo {
    height: 340px;
    margin-left: 3%;
  }
  .hally-wood-info-container {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 8%;
  }
  .music-main-title {
    font-size: 45px;
    margin-top: 8%;
    margin-bottom: 3%;
    margin-left: -1%;
    padding-left: 0;
  }
  .car-photo-music {
    height: 160px;
    margin-bottom: 7%;
    margin-top: 2%;
    margin-left: 1%;
  }
  .hally-wood-song-link-container {
    width: 60%;
    margin: 0 auto;
    padding-bottom: 1%;
    padding-top: 1%;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-bottom: 10%;
  }
  .hally-wood-song {
    margin-bottom: 5%;
    margin-top: 5%;
    font-size: 17px;
    font-weight: 200;
    margin-left: -2%;
    text-decoration: none;
    font-style: italic;
  }


  .exhibitions-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    margin-top: 6%;
    margin-bottom: 4%;
    margin-left: -1%;
    padding-left: 0;
  }
  .car-photo-vehicle-editions-exhibitions {
    height: 95px;
    margin-bottom: 7%;
    margin-top: 2%;
  }

  .available-titles-info-div-rudy {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-bottom: 10%;
    width: 80%;
  }


  .pub-consulting-car-photo {
    height: 105px;
    margin-bottom: 2%;
    margin-top: 2%;
  }
  .pub-consulting-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 37px;
    margin-top: 6%;
    padding-bottom: 4%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }


  .books-text {
    font-weight: 200;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .books-title {
    font-family: "Montserrat", sans-serif;
    border-bottom: 0;
    margin-bottom: 10%;
    text-align: center;
    font-size: 30px;
  }

}


@media screen and (max-width: 776px) {

  .titles-name {
    width: 100%;
    font-family: "Montserrat", sans-serif;  
    font-size: 17px;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .titles-author-span {
    text-align: center;
  }
  .titles-name-typings {
    font-family: "Montserrat", sans-serif;  
    font-size: 17px;
    font-weight: 800;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  #no-print-divs {
    margin-top: 5%;
    
  }
  .no-print-info-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 8%;
    width: 100%;
  }
}




@media screen and (max-width: 747px) {
  .name-link {
    display: inline;
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0% 2% 1px 5.5%;
    text-decoration: none;
  }
  .bm-burger-button {
    position: fixed;
    width: 27px;
    height: 22px;
    left: 30px;
    top: 26px;
  }


	.professional-services-main-title {
    font-size: 40px;
    margin-top: 5%;
    padding-bottom: 3%;
  }
  #pro-serv-car-photo-1 {
    height: 115px;
    margin-bottom: 2%;
    margin-top: 4%;
  }

  .unavailable-titles-header-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 38px;
    margin-top: 10%;
    margin-bottom: 3%;
  }
  .unavailable-titles-car-photo {
    margin-top: 5%;
    height: 100px;
    margin-bottom: 5%;
  }
  
  .resume-info-main-container {
    width: 85%;
    margin: 0 auto;
  }
}






@media screen and (max-width: 725px) {
	.benson-gallery-div-2pics {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-flow: column;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 2%;
  }
  .hally-album-photo-left {
    height: 320px;
    margin-left: 3%;
    margin-bottom: 2%;
  }
  .hally-album-photo-right {
    height: 320px;
    margin-left: 3%;
  }
  .annabel-playing-guitar-photo {
    height: 340px;
    margin-left: 3%;
  }
  .hally-wood-info-container {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 8%;
  }

  .car-photo-resume {
    height: 100px;
    margin-bottom: 6%;
    margin-top: 4%;
  }
  
  .resume-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 43px;
    margin-top: 5%;
    padding-bottom: 2%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .pub-consulting-ul {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 3%;
    width: 100%;
    padding-left: 0;
  }

}





@media screen and (max-width: 709px) {
  .grants-container {
    padding: 2%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .awards-container {
    padding: 2%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .benson-gallery-photo-left {
    height: 300px;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 0;
  }
  .benson-gallery-photo-right {
    height: 300px;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 0;
  }
  .train-ride-photo-bottom {
    height: 300px;
    margin-top: 5%;
  }
}




@media screen and (max-width: 667px) {
  /* mobile */  
  .about-text-titles {
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-size: 25px;
    margin-bottom: 10%;
    font-style: italic;
    width: 100%;
    text-align: center;
    margin-top: 5%;
  }

  .news-container-boxes {
    margin: 3% 4% 8% 4%;
    padding: 7%;
    box-shadow: 0px 1px 26px -8px rgba(113, 209, 189, .6);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 80%;
  }


  .book-of-signs-main-container {
    width: 80%;
    margin-left: 0;
    margin-top: 7%;
    padding-left: 0;
  }
  .book-of-signs-img {
    height: 330px;
    margin: 0 auto;
  }
  .rudy-dynamic-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* width: 100%; */
  }
  .signs-dynamic-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
  }
  .text {
    font-family: 'Cutive Mono', monospace;
    font-weight: 200;
    font-size: 17px;
    text-align: center;
  }
  .text-last {
    font-family: 'Cutive Mono', monospace;
    font-weight: 200;
    font-size: 17px;
    text-align: center;
    margin-bottom: 5%;
  }
  
  .bold-text {
    font-family: 'Cutive Mono', monospace;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }
  
  .cta {
    text-decoration: none;
    color: inherit;
    position: relative;
    margin-left: 35%;
    padding-top: 12px;
    padding-left: 4%;
    padding-right: 4%;
    transition: all 0.2s ease;
  }

  .available-titles-info-div-rudy {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-bottom: 10%;
    width: 86%;
  }
  
  
  .writing-samples-car-photo {
    height: 100px;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .writing-samples-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 38px;
    margin-top: 8%;
    padding-bottom: 3%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 0px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }
}





@media screen and (max-width: 617px) {
  .professional-services-main-title {
    font-size: 38px;
    margin-top: 5%;
    padding-bottom: 3%;
  }
  #pro-serv-car-photo-1 {
    height: 105px;
    margin-bottom: 2%;
    margin-top: 4%;
  }

  .writing-main-title {
    font-size: 45px;
    margin-top: 6%;
    padding-bottom: 0%;
  }
  .car-photo-writing {
    height: 210px;
    margin-top: -1%;
    margin-bottom: 2%;
  }

  .hally-album-photo-left {
    height: 300px;
    margin-left: 3%;
    margin-bottom: 2%;
  }
  .hally-album-photo-right {
    height: 300px;
    margin-left: 3%;
  }
  .annabel-playing-guitar-photo {
    height: 320px;
    margin-left: 3%;
  }
  .hally-wood-info-container {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 8%;
  }

  .contact-form-container {
    width: 60%;
    margin: 0 auto;
  }

  .news-book-of-sign-img {
    margin-top: 6%;
    margin-bottom: 6%;
    height: 270px;
  }

  .news-container-box-text {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: black;
    font-weight: 200;
    margin-top: 5%;
  }


  .grants-awards-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 38px;
    margin-top: 7%;
    margin-bottom: 7%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .buy-now-div2 {
    margin-bottom: 8%;
    margin-top: 6%;
    margin-left: -10%;
  }

  .car-photo-vehicle-editions-available {
    height: 100px;
    margin-top: 2%;
    margin-bottom: 7%;
  }

  .available-titles-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 38px;
    margin-top: 5%;
    margin-bottom: 3%;
  }


  .resume-info-chunk {
    margin-top: 5%;
    margin-bottom: 7%;
  }
  .resume-title-city-date-2 {
    font-size: 15px;
    font-style: italic;
  }
  .resume-title-city-date-3 {
    font-size: 15px;
    font-style: italic;
    margin-bottom: 2%;
  }


  .heinkel-airplane-pic {
    height: 280px;
    margin-bottom: 10%;
  }



  .clients-car-photo-6 {
    height: 150px;
    margin-top: 6%;
    margin-bottom: 13%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }


  .dennis-img-secondary {
    width: 55%;
  }

}





@media screen and (max-width: 535px) {
  /* mobile */
	
  .name-link {
    display: inline;
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0% 2% 1px 6.5%;
    text-decoration: none;
  }
  .bm-burger-button {
    position: fixed;
    width: 27px;
    height: 22px;
    left: 30px;
    top: 26px;
  }

	.veh-ed-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 38px;
    margin-top: 10%;
    padding-bottom: 7%;
    color: rgba(0, 0, 0, .8);
    font-weight: 400;
    text-align: center;
    text-shadow: 3px 3px 0px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }
  .car-photo-vehicle-editions-1 {
    height: 110px;
    margin-bottom: 6%;
    margin-top: 2%;
  }

  .annabel-playing-guitar-photo {
    height: 300px;
    margin-left: 3%;
  }

  .about-about-text {
    width: 100%;
    margin: 0 auto;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 200;
    text-align: center;
  }
  .about-img-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-bottom: 7%;
  }
  .about-car-img-div {
    margin: 0 auto;
    margin-bottom: 7%;
  }
  .car-photo-about {
    height: 125px;
  }
  .about-profile-img {
    height: 185px;
  }

  .benson-gallery-photo-left {
    height: 250px;
    margin-top: 5%;
    margin-bottom: 1%;
    padding: 0;
  }
  .benson-gallery-photo-right {
    height: 250px;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 0;
  }
  .train-ride-photo-bottom {
    height: 250px;
    margin-top: 5%;
  }

  .exhibitions-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 38px;
    margin-top: 6%;
    margin-bottom: 6%;
    margin-left: -1%;
    padding-left: 0;
  }
  .car-photo-vehicle-editions-exhibitions {
    height: 90px;
    margin-bottom: 7%;
    margin-top: 2%;
  }

  .rudy-conversations-link-2 {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 3%;
  }
  .convo-with-rudy-img {
    height: 300px;
    margin-right: 0%;
  }
  .convo-with-rudy-2-img {
    height: 300px;
    margin-left: 0%;
    margin-top: 5%;
  }

  .available-titles-info-div-rudy {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-bottom: 10%;
    margin-top: 10%;
    width: 86%;
  }


  .rudy-text-body-1 {
    text-align: center;
  }
  .rudy-text-body {
    text-align: center;
  }

  .car-photo-resume {
    height: 85px;
    margin-bottom: 6%;
    margin-top: 4%;
  }
  
  .resume-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    margin-top: 5%;
    padding-bottom: 2%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .heinkel-airplane-pic {
    height: 250px;
    margin-bottom: 10%;
  }



  .clients-car-photo {
    height: 94px;
    margin-bottom: 6%;
    margin-top: 5%;
  }
  .clients-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 33px;
    margin-top: 7%;
    margin-bottom: 3%;
    margin-left: -1%;
    padding-left: 0;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }


  .car-photo-magazines {
    height: 180px;
    margin-bottom: 2%;
    margin-top: -2%;
  }
  .magazines-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 42px;
    margin-top: 8%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  
  .poems-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 41px;
    margin-top: 9%;
    margin-bottom: 4%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

}





@media screen and (max-width: 510px) {
  .pro-serv-title-div {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    margin-top: 4%;
    margin-bottom: 2%;
  }
  .professional-services-main-title {
    font-size: 38px;
    margin-top: 5%;
    padding-bottom: 5%;
  }
  #pro-serv-car-photo-1 {
    height: 100px;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .hally-album-photo-left {
    height: 280px;
    margin-left: 3%;
    margin-bottom: 2%;
  }
  .hally-album-photo-right {
    height: 280px;
    margin-left: 3%;
  }
  .annabel-playing-guitar-photo {
    height: 280px;
    margin-left: 3%;
  }
  .hally-wood-info-container {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 14%;
  }

  .music-line-divider {
    height: 60px;
    opacity: .5;
  }
  .music-main-title {
    font-size: 43px;
    margin-top: 12%;
    margin-bottom: 5%;
    margin-left: -1%;
    padding-left: 0;
  }

  .news-container-box-title {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    color: black;
    font-weight: 200;
  }


  .pub-consulting-car-photo {
    height: 95px;
    margin-bottom: 6%;
    margin-top: 6%;
  }
  .pub-consulting-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 34px;
    margin-top: 9%;
    padding-bottom: 4%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .clients-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
  }
  .clients-info-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 3%;
    width: 100%;
  }
  
}





@media screen and (max-width: 487px) {
  /* mobile */
  .writing-main-title {
    font-size: 45px;
    margin-top: 12%;
    padding-bottom: 0%;
  }
  .car-photo-writing {
    height: 190px;
    margin-top: -4%;
    margin-bottom: 4%;
  }

  .annabel-playing-guitar-photo {
    height: 260px;
    margin-left: 3%;
  }

  .hally-wood-title-container {
    width: 70%;
    margin: 0 auto;
  }
  .music-main-title {
    font-size: 42px;
    margin-top: 12%;
    margin-bottom: 3%;
    margin-left: -1%;
    padding-left: 0;
  }
  .car-photo-music {
    height: 140px;
    margin-bottom: 9%;
    margin-top: 5%;
    margin-left: 1%;
  }

  .contact-form-container {
    width: 70%;
    margin: 0 auto;
  }

  .news-book-of-sign-img {
    margin-top: 6%;
    margin-bottom: 6%;
    height: 220px;
  }

  .resume-info-chunk {
    margin-top: 8%;
    margin-bottom: 10%;
  }


  .heinkel-airplane-pic {
    height: 220px;
    margin-bottom: 10%;
  }


  .clients-car-photo-6 {
    height: 120px;
    margin-top: 6%;
    margin-bottom: 13%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }

  .books-title {
    font-family: "Montserrat", sans-serif;
    border-bottom: 0;
    margin-bottom: 10%;
    margin-top: 10%;
    text-align: center;
    font-size: 25px;
  }


  .car-photo-books {
    height: 180px;
    margin-bottom: 4%;
    margin-top: -2%;
  }
  .books-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 45px;
    margin-top: 10%;
    padding-bottom: 0%;
  }
}






@media screen and (max-width: 435px) {
  /* mobile */

  .professional-services-main-title {
    font-size: 33px;
    margin-top: 12%;
    padding-bottom: 5%;
  }
  #pro-serv-car-photo-1 {
    height: 95px;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .pro-serv-nav-ul{
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding: 0;
    width: 80%;
    margin: 0 auto;
  }
  .pro-serv-nav-li { 
    text-align: center;
    margin: 5% 0% 5% 0%;
  }
  
  .annabel-playing-guitar-photo {
    height: 240px;
    margin-left: 3%;
  }
  .hally-wood-song-link-container {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 1%;
    padding-top: 1%;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-bottom: 10%;
  }

  .contact-title {
    font-size: 40px;
    margin-top: 7%;
  }

  .news-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    margin-top: 8%;
    margin-bottom: 8%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .car-photo-vehicle-editions-grants {
    margin-top: 2%;
    height: 95px;
    margin-bottom: 7%;
  }

  .grant-year {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .award-year {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .awards-title {
    text-align: center;
    margin-top: 12%;
    margin-bottom: 5%;
  }
  .grant-title {
    text-align: center;
    margin-top: 12%;
    margin-bottom: 5%;
  }


  .benson-gallery-photo-left {
    height: 200px;
    margin-top: 5%;
    margin-bottom: 1%;
    padding: 0;
  }
  .benson-gallery-photo-right {
    height: 200px;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 0;
  }
  .train-ride-photo-bottom {
    height: 200px;
    margin-top: 5%;
  }

  .exhibitions-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 38px;
    margin-top: 8%;
    margin-bottom: 6%;
    margin-left: -1%;
    padding-left: 0;
  }
  .car-photo-vehicle-editions-exhibitions {
    height: 80px;
    margin-bottom: 9%;
    margin-top: 5%;
  }

  .typings-img {
    height: 270px;
    margin-right: 0%;
  }
  .typings-2-img {
    height: 270px;
    margin-left: 0%;
    margin-top: 3%;
  }

  .unavailable-titles-header-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 35px;
    margin-top: 10%;
    margin-bottom: 3%;
  }
  .unavailable-titles-car-photo {
    margin-top: 5%;
    height: 85px;
    margin-bottom: 5%;
  }

  #pub-consulting-li-copyediting {
    margin-right: 5%;
  }

  .pub-consulting-li {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 16px;
    margin-top: 12%;
    list-style-type: none;
    padding: 1% 4% 1% 4%;
    border-radius: 50px;
    -webkit-animation: text-shadow-drop-center  1s .8s both;
    animation: text-shadow-drop-center  1s .8s both;
    transition: .4s ease-in-out;
    text-align: center;
    margin-right: 0;
  }


  .heinkel-airplane-pic {
    height: 180px;
    margin-bottom: 10%;
  }


  .clients-car-photo {
    height: 85px;
    margin-bottom: 6%;
    margin-top: 5%;
  }
  .clients-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    margin-top: 7%;
    margin-bottom: 3%;
    margin-left: -1%;
    padding-left: 0;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .books-text {
    font-weight: 200;
    text-align: center;
    margin-top: 4%;
    margin-bottom: 6%;
  }


  .line {
    margin: 0 auto;
    width: 90%;
    margin-bottom: 5%;
  }
  
}





@media screen and (max-width: 400px) {
  /* mobile */
  .name-link {
    display: inline;
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0% 2% 1px 8.5%;
    text-decoration: none;
  }
  .bm-burger-button {
    position: fixed;
    width: 27px;
    height: 22px;
    left: 30px;
    top: 26px;
  }

  .veh-ed-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 33px;
    margin-top: 10%;
    padding-bottom: 7%;
    color: rgba(0, 0, 0, .8);
    font-weight: 400;
    text-align: center;
    text-shadow: 3px 3px 0px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }
  .car-photo-vehicle-editions-1 {
    height: 92px;
    margin-bottom: 6%;
    margin-top: 2%;
  }

  .annabel-playing-guitar-photo {
    height: 200px;
    margin-left: 3%;
  }
  .hally-album-photo-left {
    height: 250px;
    margin-left: 3%;
    margin-bottom: 2%;
  }
  .hally-album-photo-right {
    height: 250px;
    margin-left: 3%;
  }
  .music-line-divider {
    height: 40px;
    opacity: .5;
  }

  .social-container {
    width: 300px;
    margin: 0 auto;
    text-align: center;
    padding-top: .5%;
  }

  .news-train-ride-img {
    margin-top: 6%;
    margin-bottom: 6%;
    height: 220px;
  }

  .grants-awards-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 35px;
    margin-top: 7%;
    margin-bottom: 7%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .cta {
    text-decoration: none;
    color: inherit;
    position: relative;
    margin-left: 29%;
    padding-top: 12px;
    padding-left: 4%;
    padding-right: 4%;
    transition: all 0.2s ease;
  }

  .typings-img {
    height: 250px;
    margin-right: 0%;
  }
  .typings-2-img {
    height: 250px;
    margin-left: 0%;
    margin-top: 3%;
  }

  .dennis-img-secondary {
    width: 75%;
  }
}






@media screen and (max-width: 380px) {
  /* mobile */
  #pro-serv-car-photo-1 {
    height: 87px;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .news-book-of-sign-img {
    margin-top: 6%;
    margin-bottom: 6%;
    height: 190px;
  }
  .news-container-box-title {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: black;
    font-weight: 200;
  }


  .pub-consulting-car-photo {
    height: 85px;
    margin-bottom: 6%;
    margin-top: 6%;
  }
  .pub-consulting-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    margin-top: 9%;
    padding-bottom: 4%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }



  .clients-car-photo-1 {
    height: 150px;
    margin-top: 6%;
    margin-bottom: 12%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }
  .clients-car-photo-2 {
    height: 180px;
    margin-top: 6%;
    margin-bottom: 12%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }
  .clients-car-photo-3 {
    height: 180px;
    margin-top: 6%;
    margin-bottom: 16%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }
  .clients-car-photo-4 {
    height: 180px;
    margin-top: 6%;
    margin-bottom: 13%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }
  .clients-car-photo-5 {
    height: 180px;
    margin-top: 6%;
    margin-bottom: 13%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }
  .clients-car-photo-6 {
    height: 100px;
    margin-top: 6%;
    margin-bottom: 13%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }

  
}





@media screen and (max-width: 370px) {
  /* mobile */
  .writing-main-title {
    font-size: 40px;
    margin-top: 12%;
    padding-bottom: 0%;
  }
  .car-photo-writing {
    height: 170px;
    margin-top: -4%;
    margin-bottom: 4%;
  }

  .annabel-playing-guitar-photo {
    height: 180px;
    margin-left: 3%;
  }
  .hally-wood-title-container {
    width: 90%;
    margin: 0 auto;
  }
  .hally-album-photo-left {
    height: 230px;
    margin-left: 3%;
    margin-bottom: 2%;
  }
  .hally-album-photo-right {
    height: 230px;
    margin-left: 3%;
  }
  .music-main-title {
    font-size: 40px;
    margin-top: 10%;
    margin-bottom: 3%;
    margin-left: -1%;
    padding-left: 0;
  }
  .car-photo-music {
    height: 130px;
    margin-bottom: 15%;
    margin-top: 8%;
    margin-left: 1%;
  }

  .news-train-ride-img {
    margin-top: 6%;
    margin-bottom: 6%;
    height: 200px;
  }


  .news-container-box-text {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: black;
    font-weight: 200;
    margin-top: 7%;
  }


  .news-container-boxes {
    margin: 15% 4% 8% 4%;
    padding: 7%;
    box-shadow: 0px 1px 26px -8px rgba(113, 209, 189, .6);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 92%;
  }
  
  .grants-awards-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    margin-top: 7%;
    margin-bottom: 7%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }


  .benson-gallery-photo-left {
    height: 175px;
    margin-top: 5%;
    margin-bottom: 1%;
    padding: 0;
  }
  .benson-gallery-photo-right {
    height: 175px;
    margin-top: 3%;
    margin-bottom: 1%;
    padding: 0;
  }
  .train-ride-photo-bottom {
    height: 175px;
    margin-top: 5%;
  }

  .car-photo-vehicle-editions-available {
    height: 80px;
    margin-top: 8%;
    margin-bottom: 12%;
  }
  .available-titles-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 35px;
    margin-top: 10%;
    margin-bottom: 3%;
  }

  .available-titles-info-div-rudy {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-bottom: 10%;
    width: 100%;
  }

  .unavailable-titles-header-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    margin-top: 10%;
    margin-bottom: 3%;
  }
  .unavailable-titles-car-photo {
    margin-top: 5%;
    height: 75px;
    margin-bottom: 5%;
  }

  .resume-title-city-date {
    font-size: 15px;
    font-style: italic;
    margin-right: 4.5px;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .resume-title-city-date-2 {
    font-size: 15px;
    font-style: italic;
    margin-top: -2%;
    margin-bottom: 2%;
  }


  .car-photo-resume {
    height: 80px;
    margin-bottom: 10%;
    margin-top: 6%;
  }
  
  .resume-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 37px;
    margin-top: 10%;
    padding-bottom: 2%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }


  .heinkel-airplane-pic {
    height: 150px;
    margin-bottom: 10%;
    margin-top: 7%;
  }


  .clients-car-photo {
    height: 75px;
    margin-bottom: 9%;
    margin-top: 8%;
  }
  .clients-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    margin-top: 7%;
    margin-bottom: 3%;
    margin-left: -1%;
    padding-left: 0;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .clients-line-divider {
    height: 35px;
    opacity: .5;
  }

  .writing-samples-car-photo {
    height: 90px;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .writing-samples-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 34px;
    margin-top: 8%;
    padding-bottom: 3%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 0px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .car-photo-books {
    height: 160px;
    margin-bottom: 4%;
    margin-top: -2%;
  }
  .books-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 42px;
    margin-top: 10%;
    padding-bottom: 0%;
  }


  .books-title {
    font-family: "Montserrat", sans-serif;
    border-bottom: 0;
    margin-bottom: 13%;
    margin-top: 10%;
    text-align: center;
    font-size: 25px;
  }
  
  .magazines-span-title-text {
    font-weight: 400;
    font-size: 19px;
    font-style: italic;
  }

  .car-photo-magazines {
    height: 160px;
    margin-bottom: 2%;
    margin-top: -2%;
  }
  .magazines-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    margin-top: 8%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

}





@media screen and (max-width: 326px) {
  /* mobile */
  .name-link {
    display: inline;
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0% 2% 1px 9.5%;
    text-decoration: none;
  }
  .bm-burger-button {
    position: fixed;
    width: 27px;
    height: 22px;
    left: 27px;
    top: 26px;
  }

  .veh-ed-nav-ul{
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 0;
    margin: 0 auto;
    width: 90%;
  }
  .veh-ed-nav-li { 
    text-align: center;
    margin: 5% 5% 3% 5%;
  }
  .veh-ed-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    margin-top: 10%;
    padding-bottom: 7%;
    color: rgba(0, 0, 0, .8);
    font-weight: 400;
    text-align: center;
    text-shadow: 3px 3px 0px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 0px;
    transition: 500ms;
  }
  .car-photo-vehicle-editions-1 {
    height: 88px;
    margin-bottom: 6%;
    margin-top: 2%;
  }

  #pro-serv-car-photo-1 {
    height: 80px;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .music-line-divider {
    height: 30px;
    opacity: .5;
  }
  .hally-wood-song-link-container {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 1%;
    padding-top: 1%;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-bottom: 10%;
  }

  .hally-album-photo-left {
    height: 200px;
    margin-left: 3%;
    margin-bottom: 2%;
  }
  .hally-album-photo-right {
    height: 200px;
    margin-left: 3%;
  }
  .hally-wood-info-container {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 17%;
  }
  .social-container {
    width: 290px;
    margin: 0 auto;
    text-align: center;
    padding-top: .5%;
  }

  .contact-title {
    font-size: 35px;
    margin-top: 7%;
  }

  .news-book-of-sign-img {
    margin-top: 6%;
    margin-bottom: 6%;
    height: 150px;
  }

  .news-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 37px;
    margin-top: 8%;
    margin-bottom: 5%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .car-photo-vehicle-editions-grants {
    margin-top: 5%;
    height: 75px;
    margin-bottom: 12%;
  }

  .benson-gallery-photo-left {
    height: 160px;
    margin-top: 5%;
    margin-bottom: 1%;
    padding: 0;
  }
  .benson-gallery-photo-right {
    height: 160px;
    margin-top: 3%;
    margin-bottom: 1%;
    padding: 0;
  }
  .train-ride-photo-bottom {
    height: 160px;
    margin-top: 5%;
  }

  .book-of-signs-img {
    height: 300px;
    margin: 0 auto;
  }

  .cta {
    text-decoration: none;
    color: inherit;
    position: relative;
    margin-left: 22%;
    padding-top: 12px;
    padding-left: 4%;
    padding-right: 4%;
    transition: all 0.2s ease;
  }
  .text-last {
    font-family: 'Cutive Mono', monospace;
    font-weight: 200;
    font-size: 17px;
    text-align: center;
    margin-bottom: 10%;
  }

  .typings-img {
    height: 210px;
    margin-right: 0%;
    margin-top: 7%;
  }
  .typings-2-img {
    height: 210px;
    margin-left: 0%;
    margin-top: 3%;
  }

  .resume-title-city-date {
    font-size: 15px;
    font-style: italic;
    margin-right: 4.5px;
    margin-top: 2%;
    margin-bottom: 3%;
  }
  .resume-title-city-date-2 {
    font-size: 15px;
    font-style: italic;
    margin-top: -3%;
    margin-bottom: 3%;
  }



  .clients-car-photo-1 {
    height: 140px;
    margin-top: 6%;
    margin-bottom: 12%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }
  .clients-car-photo-2 {
    height: 170px;
    margin-top: 6%;
    margin-bottom: 12%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }
  .clients-car-photo-3 {
    height: 170px;
    margin-top: 6%;
    margin-bottom: 16%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }
  .clients-car-photo-4 {
    height: 170px;
    margin-top: 6%;
    margin-bottom: 13%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }
  .clients-car-photo-5 {
    height: 170px;
    margin-top: 6%;
    margin-bottom: 13%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }
  .clients-car-photo-6 {
    height: 90px;
    margin-top: 6%;
    margin-bottom: 13%;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  }


  .writing-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: .4s ease-in-out;
    margin-bottom: 3%;
    margin-top: 3%;
    width: 90%;
  }
  
}





@media screen and (max-width: 300px) {
	/* mobile */
  #pro-serv-car-photo-1 {
    height: 76px;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .annabel-playing-guitar-photo {
    height: 170px;
    margin-left: 3%;
  }

  .music-main-title {
    font-size: 40px;
    margin-top: 14%;
    margin-bottom: 7%;
    margin-left: -1%;
    padding-left: 0;
  }
  .car-photo-music {
    height: 130px;
    margin-bottom: 18%;
    margin-top: 8%;
    margin-left: 1%;
  }

  .social-container {
    width: 250px;
    margin: 0 auto;
    text-align: center;
    padding-top: .5%;
  }
  
  .news-train-ride-img {
    margin-top: 6%;
    margin-bottom: 6%;
    height: 190px;
  }

  .car-photo-vehicle-editions-grants {
    margin-top: 5%;
    height: 70px;
    margin-bottom: 12%;
  }

  .car-photo-vehicle-editions-available {
    height: 80px;
    margin-top: 8%;
    margin-bottom: 12%;
  }
  .available-titles-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    margin-top: 10%;
    margin-bottom: 3%;
  }

  .typings-img {
    height: 190px;
    margin-right: 0%;
    margin-top: 7%;
  }
  .typings-2-img {
    height: 190px;
    margin-left: 0%;
    margin-top: 3%;
  }

  .unavailable-titles-header-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 27px;
    margin-top: 10%;
    margin-bottom: 6%;
  }
  .unavailable-titles-car-photo {
    margin-top: 5%;
    height: 74px;
    margin-bottom: 12%;
  }

  .resume-title-city-date {
    font-size: 15px;
    font-style: italic;
    margin-right: 4.5px;
    margin-top: 2%;
    margin-bottom: 4%;
  }
  .resume-title-city-date-2 {
    font-size: 15px;
    font-style: italic;
    margin-top: -4%;
    margin-bottom: 4%;
  }

  .resume-info-chunk {
    margin-top: 12%;
    margin-bottom: 16%;
  }


  .car-photo-resume {
    height: 75px;
    margin-bottom: 10%;
    margin-top: 6%;
  }
  .resume-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 35px;
    margin-top: 10%;
    padding-bottom: 2%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .heinkel-airplane-pic {
    height: 130px;
    margin-bottom: 10%;
    margin-top: 7%;
  }


  .pub-consulting-car-photo {
    height: 75px;
    margin-bottom: 8%;
    margin-top: 8%;
  }
  .pub-consulting-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    margin-top: 9%;
    padding-bottom: 4%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }


  .writing-samples-car-photo {
    height: 80px;
    margin-bottom: 10%;
    margin-top: 10%;
  }
  .writing-samples-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 31px;
    margin-top: 10%;
    padding-bottom: 3%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 0px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }

  .car-photo-magazines {
    height: 150px;
    margin-bottom: 2%;
    margin-top: -2%;
  }
  .magazines-main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 38px;
    margin-top: 12%;
    color: rgba(0, 0, 0, .8);
    font-weight: 200;
    text-align: center;
    text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
    letter-spacing: 2px;
    word-spacing: 3px;
    transition: 500ms;
  }
}






@media screen and (max-width: 284px) {
	/* mobile */
	.name-link {
    display: inline;
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0% 2% 1px 6.5%;
    text-decoration: none;
  }
  .bm-burger-button {
    position: fixed;
    width: 27px;
    height: 22px;
    left: 22px;
    top: 26px;
  }

  .writing-main-title {
    font-size: 40px;
    margin-top: 12%;
    padding-bottom: 0%;
  }
  .car-photo-writing {
    height: 150px;
    margin-top: -4%;
    margin-bottom: 4%;
  }
  .social-container {
    width: 240px;
    margin: 0 auto;
    text-align: center;
    padding-top: .5%;
  }
  
  .benson-gallery-photo-left {
    height: 150px;
    margin-top: 5%;
    margin-bottom: 1%;
    padding: 0;
  }
  .benson-gallery-photo-right {
    height: 150px;
    margin-top: 3%;
    margin-bottom: 1%;
    padding: 0;
  }
  .train-ride-photo-bottom {
    height: 150px;
    margin-top: 5%;
  }

  .book-of-signs-img {
    height: 280px;
    margin: 0 auto;
  }
}