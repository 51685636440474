.books-main-container {
  height: 910px;
  width: 100%;

}


.books-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}
.car-photo-books {
  height: 200px;
  margin-bottom: 4%;
  margin-top: -2%;
}
.books-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 45px;
  margin-top: 5%;
  padding-bottom: 1%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.books-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.books-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.books-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin: 0 auto;
}

.books-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: 10%;
  text-align: center;
}

.book-text-bold {
  font-weight: 600;
  text-align: center;
}

.books-text {
  font-weight: 200;
  text-align: center;
}