
.no-print-header {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.unavailable-titles-header-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 42px;
  margin-top: 5%;
  margin-bottom: 3%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.unavailable-titles-header-text:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.unavailable-titles-car-photo {
  margin-top: 2%;
  height: 110px;
  margin-bottom: 5%;
}

.unavailable-titles-container {
  display: flex;
  flex-direction: row;
}

.unavailable-titles-left-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 3%;
}

/* .last-titles-divs {
  border-bottom: 1px solid white;
} */

.titles-dynamic-info-right {
  margin-right: 10%;
  margin-top: 6%;
  box-shadow: 0px 1px 26px -8px rgba(0,0,0,0.41);
  width: 35%;
  border-radius: 15px;
  height: 760px;
  margin-left: 13%;
  /* border: 3px solid rgb(180, 180, 180); */
  background-image: url('https://i.imgur.com/Ds42QE1.png');
  background-size: cover;
}

.dynamic-info-title-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-size: 27px;
  border-bottom: 1px solid black;
  width: 53%;
  margin: 0 auto;
  padding-top: 5%;
}


.no-print-info-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 3%;
  width: 100%;
}
.no-print-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: .4s ease-in-out;
  margin-bottom: 2%;
}



.line-img {
  width: 50%;
}


.train-ride-cover {
  padding-bottom: -1%;
}




.fast-lanes-cover {
  margin-left: 2.8%;
}

.the-day-before-cover {
  margin-left: 2.8%;
}



.titles-author {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  /* font-style: italic; */
  font-size: 15px;
}




.unavailable-titles-links {
  color: black;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  width: 100%;
}

#no-link:hover {
  text-decoration: none;
}






/* –––––––––––––––––––––––– */
#button-modal {
  background: none !important;
  border: none !important;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}
#button-modal-2 {
  background: none !important;
  border: none !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}


.clients-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.clients-info-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 70%;
  margin-bottom: 7%;
}

.unavailable-titles-divs {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  flex-flow: column;
  text-decoration: none;
  width: 93%;
  /* border-top: 2px solid black; */
  /* border-bottom: 1px solid rgb(187, 187, 187); */
  padding-top: 1%;
}



.clients-div {
  width: 75%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: .4s ease-in-out;
  margin-bottom: 2%;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-direction: row;
}
#button-modal {
  width: 100%;
  background: none !important;
  border: none !important;
}
.button-modal-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: row;
}

.titles-author-span {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
  font-size: 15px;
  color: rgb(95, 94, 94);
  text-decoration: none;
  margin-left: 1%;
}
#titles-author-span-id:hover {
  text-decoration: none;
}

.titles-name-score {
  font-family: "Montserrat", sans-serif;  
  font-size: 17px;
  font-weight: 800;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: -1%;
}

.titles-name-typings {
  font-family: "Montserrat", sans-serif;  
  font-size: 17px;
  font-weight: 800;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  align-content: center;
}



.titles-cover {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
  font-size: 15px;
  color: rgb(95, 94, 94);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: row;
}
.titles-name {
  width: 100%;
  font-family: "Montserrat", sans-serif;  
  font-size: 17px;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  align-content: center;
}
.titles-name:hover {
  text-decoration: underline;
}
/* 
.smithsonian-cover {

}
.smithsonian-name {

} */


.svg-buy-now {
  margin-top: -1px;
}

#buy-now-link {
  margin-bottom: 3%;
  margin-top: 3%;
}

.light-and-shadow-main-container {
  width: 80%;
  margin-bottom: 5%
}

.light-and-shadow-text-last {
  margin-bottom: 6px;
}

.light-and-shadow-photo-text {
  font-weight: 200;
  font-size: 13px;
  margin-top: 1%;
  margin-left: 2%;
}

.quilts-image {
  height: 240px;
}

.counting-image {
  height: 240px;
  margin-right: 3%;
}

.train-image {
  height: 240px;
  margin-right: 3%;
}