.car-photo-music-art {
  height: 130px;
  margin-bottom: 4%;
  margin-top: 2%;
}

.music-art-main-title {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  margin-top: 5%;
  margin-bottom: 2%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  width: 30%;
  text-align: center;
}