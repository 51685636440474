@import url("https://fonts.googleapis.com/css?family=Montserrat");

/* .nav {
  /* border-bottom: 1px solid black; 
} */

.nav-container {
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  text-decoration: none;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center; */
}

.nav-bullets-container {
  list-style: none;
  width: 100%;
  display: flex;
  height: 75px;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 0;
}

.nav-name {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  text-decoration: none;
}

.top-link {
  text-decoration: none !important;
  color: white !important;
  font-weight: 600;
}

#my-name .home-link {
  text-decoration: none;
  color: black;
  font-family: "Lekton", sans-serif;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: row-reverse;
  width: 100%;
}

a {
  text-decoration: none !important;
}

#annabel-name {
  font-size: 23px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  /* border-top: 1px solid black;
  border-bottom: 1px solid black; */
  font-weight: 400;
  text-decoration: none !important;
  margin-bottom: 0;
}

.name-div {
  margin-left: 4%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.name-link {
  display: inline;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  list-style: none;
  margin: 0% 2% 1px 3.5%;
  text-decoration: none;
}

.links {
  display: inline;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0% 2% 0% 1%;
  text-decoration: none;
}

.first-link {
  display: inline;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0% 2% 0% 8%;
}

.links-end-about {
  display: inline;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0% 1% 0% 7%;
  text-decoration: none;
}

.links-end-contact {
  display: inline;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0% 0% 0% 2%;
  text-decoration: none;
}

.noDropDown {
  text-decoration: none !important;
  color: black !important;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

.nav-text-links {
  text-decoration: none;
  color: black;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  width: 100%;
}
/* ••••••••••••••••••••••••••••••••••••••••••••••••••• */


/*.nav-text-links:hover {
  text-decoration: underline;
  color: black;
}*/

strong {
  font-family: "Sarina", cursive;
  font-size: 35px;
  /*font-family: "Chango", cursive;*/
  /*  font-family: 'Gravitas One', cursive;*/
}



/* other prefixed animation blocks ommitted for brevity */

h4 {
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
  color: black;
  text-align: center;
}

.sm-link {
  --uismLinkDisplay: var(--smLinkDisplay, inline-flex);
  --uismLinkTextColor: var(--smLinkTextColor);
  --uismLinkTextColorHover: var(--smLinkTextColorHover);

  display: var(--uismLinkDisplay);
  color: var(--uismLinkTextColor);
  position: relative;
  overflow: hidden;
}

/* ••••••••••••••••••••••••••••••••••••••••••••••••••• */


a.sm-link {
  text-decoration: none;
}
/* ••••••••••••••••••••••••••••••••••••••••••••••••••• */


.sm-link__label {
  display: block;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  font-size: 17px;
  font-weight: 600;
}

/* ••••••••••••••••••••••••••••••••••••••••••••••••••• */


.down-arrow {
  padding-top: 2px;
  padding-left: 4px;
  height: 7px;
}

/* sm-link_padding-all */

.sm-link_padding-all {
  --uismLinkLineWeight: var(--smLinkLineWeight, 2px);
  --uismLinkLineColor: var(--smLinkLineColor, white);
  --uismLinkPadding: var(--smLinkPadding, 5px);
  --uismLinkPaddingRight: var(--smLinkPaddingRight, 6px);
  --uismLinkPaddingLeft: var(--smLinkPaddingRight, 6px);
  padding-right: var(--uismLinkPaddingRight);
  padding-top: var(--uismLinkPadding);
  padding-bottom: var(--uismLinkPadding);
  padding-left: var(--uismLinkPaddingLeft);
}

/* ••••••••••••••••••••••••••••••••••••••••••••••••••• */


.sm-link_padding-all::before,
.sm-link_padding-all::after {
  width: 100%;
  height: var(--uismLinkLineWeight);
  left: 0;
}

.sm-link_padding-all::before {
  top: 0;
}

.sm-link_padding-all::after {
  bottom: 0;
}

.sm-link_padding-all .sm-link__label::before,
.sm-link_padding-all .sm-link__label::after {
  width: var(--uismLinkLineWeight);
  height: 100%;
  top: 0;
}

.sm-link_padding-all .sm-link__label::before {
  left: 0;
}

.sm-link_padding-all .sm-link__label::after {
  right: 0;
}

.sm-link_padding-all::before,
.sm-link_padding-all::after,
.sm-link_padding-all .sm-link__label::before,
.sm-link_padding-all .sm-link__label::after {
  content: "";
  background-color: var(--uismLinkLineColor);
  position: absolute;
  opacity: 0;

  will-change: transform, opacity;
  transition-property: transform, opacity;
}

.sm-link_padding-all:hover::before,
.sm-link_padding-all:hover::after,
.sm-link_padding-all:hover .sm-link__label::before,
.sm-link_padding-all:hover .sm-link__label::after {
  opacity: 1;
}

/* sm-link_padding-bottom */

.sm-link_padding-bottom {
  --uismLinkLineWeight: var(--smLinkLineWeight, 2px);
  --uismLinkLineColor: var(--smLinkLineColor, white);

  padding-bottom: var(--uismLinkLineWeight);
  position: relative;
}

.sm-link_padding-bottom::after {
  content: "";
  width: 100%;
  height: var(--uismLinkLineWeight);
  background-color: var(--uismLinkLineColor);

  position: absolute;
  left: 0;
  bottom: 0;
}

/* sm-link_bg */

.sm-link_bg {
  --uismLinkLineColor: var(--smLinkLineColor, white);
  --uismLinkTextColorHover: var(--smLinkTextColorHover, #fff);
  --uismLinkPadding: var(--smLinkPadding, 5px);

  padding: var(--uismLinkPadding);
  transition: color 0.3s ease-out;
}

.sm-link_bg::before,
.sm-link_bg::after {
  content: "";
  background-color: var(--uismLinkLineColor);
  opacity: 0;
  position: absolute;

  transition: transform 0.2s ease-out, opacity 0.2s ease-out 0.03s;
}

.sm-link_bg .sm-link__label {
  position: relative;
  z-index: 2;
}

.sm-link_bg:hover::before,
.sm-link_bg:hover::after {
  opacity: 1;
  transition-duration: 0.35s, 0.35s;
  transition-delay: 0s, 0s;
  border-top: none;
}

.sm-link_bg:hover {
  color: var(--uismLinkTextColorHover);
}

/* sm-link_text */

.sm-link_text::before {
  content: attr(data-sm-link-text);
  color: var(--uismLinkTextColorHover);
  position: absolute;
}

.sm-link_text::before,
.sm-link_text .sm-link__label {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.86, 0.6, 0.08, 1.01);
  transition-duration: 0.3s;
}

.sm-link_text:hover::before,
.sm-link_text:hover .sm-link__label {
  transition-duration: 0.4s;
  border-top: none;
}

/* effect 1 */

.sm-link1::before {
  transform: translate3d(-105%, 0, 0);
}

.sm-link1::after {
  transform: translate3d(105%, 0, 0);
}

.sm-link1 .sm-link__label::before {
  transform: translate3d(0%, -100%, 0);
}

.sm-link1 .sm-link__label::after {
  transform: translate3d(0%, 100%, 0);
}

.sm-link1::before,
.sm-link1::after,
.sm-link1 .sm-link__label::before,
.sm-link1 .sm-link__label::after {
  transition-timing-function: ease-out;
  transition-duration: 0.2s, 0.15s;
  transition-delay: 0s, 0.15s;
}

.sm-link1:hover::before,
.sm-link1:hover::after,
.sm-link1:hover .sm-link__label::before,
.sm-link1:hover .sm-link__label::after {
  transform: translate3d(0, 0, 0);
  opacity: 1;

  transition-duration: 0.25s;
  transition-delay: 0s;
  border-top: none;
}








/* Dropdown Button */

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
  z-index: 1;
  background-color: white;
  text-decoration: none;
}

#vehicle-tab-drop {
  width: 120%;
  border-top: 7px solid black
}

#writing-drop {
  width: 210%;
  border-top: 7px solid black
}

#publications-drop {
  width: 114%;
  border-top: 7px solid black
}

#music-and-art-tab-drop {
  width: 105%; 
}

#e-and-p-services-link {
  text-align: center;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 5% 0% 5% 0%;
  text-decoration: none;
  display: block;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  
}

#drop-link-hover:hover {
  /* text-decoration: underline; */
  color: #cf2320;
  transition: 100ms;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}



/* Change color of dropdown links on hover */
/* .dropdown-content a:hover {
  text-decoration: underline;
} */



/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
}


/* ••••••••••••••••••••••••••••••••••••••••••••••••••• */

.home-img {
  position: fixed;
  height: 23px;
  right: 30px;
  top: 25px;
  opacity: .9;
  transition: 200ms;
  border-radius: 50%;
  padding: 1px;
  -webkit-animation: home-img-opacity  2s 5s both;
  animation: home-img-opacity  2s 5s both;
}
.home-img:hover {
  background-color: rgb(204,104,102);
  border-radius: 50%;
  transform: scale(1.5);
  border: 1px solid black;
  transition: 200ms;
}

@-webkit-keyframes home-img-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes home-img-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.nav-white-line {
  border-bottom: 3px solid white;
  width: 50%;
  margin-top: -5%;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 27px;
  height: 22px;
  left: 30px;
  top: 26px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  opacity: .9;
}

/* General sidebar styles */
.bm-menu {
  background: #000000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  text-decoration: none;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

/* Individual item */
.bm-item {
  display: inline-block;
  text-decoration: none !important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

#hamburger-link-main-div:focus {
  text-decoration: none !important;
  outline: 0;
}

#hamburger-link-main-div {
  margin-bottom: 7%;
  margin-top: 3%;
}


#hamburger-link-main-div-bottom {
  margin-bottom: 12%;
  margin-top: 16%;
}
#hamburger-link-main-div-bottom:focus {
  text-decoration: none !important;
  outline: 0;
}

#drop-link-hover-available-titles {
  color: white;
  font-weight: 800;
  font-size: 17px;
  font-style: italic;
}
#drop-link-hover-available-titles:hover {
  /* text-decoration: underline; */
  color: white;
  transition: 100ms;
}
