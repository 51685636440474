.exhibitions-main-container {
  /* background-image: url('https://i.imgur.com/0ht2wHa.jpg'); */
  /* height: 1010px; */
  width: 100%;
}

.exhibitions-main-container {
  height: 910px;
  width: 100%;
}

.exhibitions-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}


.exhibitions-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 45px;
  margin-top: 5%;
  margin-bottom: 3%;
  margin-left: -1%;
  padding-left: 0;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.exhibitions-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.car-photo-vehicle-editions-exhibitions {
  height: 110px;
  margin-bottom: 7%;
  margin-top: 2%;
}

.benson-gallery-div {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 2%;
}

.benson-gallery-photo-left {
  height: 300px;
}

.benson-gallery-photo-right {
  height: 300px;
}

.benson-gallery-div-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 3%;
}

.train-ride-photo-bottom {
  height: 400px;
}

.train-ride-photo-description {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-style: italic;
  margin-top: -2.5%;
  margin-bottom: 4%;
}

.benson-images-description {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-style: italic;
  margin-top: -2.5%;
  margin-bottom: 4%;
  text-align: center;
}



.exhibitions-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.regular-exhibitions-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
}
.regular-exhibitions-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: 7%;
}
.exhibitions-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

.trade-show-container {
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.trade-show-title-container {
  width: 100%;
}
.trade-show-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);
  margin: 0 auto;
  margin-bottom: 2%;
  margin-top: 10%;
  text-align: center;
  width: 30%;
}




.span-italic {
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-size: 15px;
}

.exhibition-year {
  margin-top: 7%;
  font-family: "Montserrat", sans-serif;  
  font-weight: 800;
}
.exhibition-name {
  font-family: "Montserrat", sans-serif;  
  font-weight: 200;
  margin-top: -1%; 
  font-size: 15px;
}
.exhibition-location {
  margin-top: -3%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 500;
}

.exhibition-name1 {
  font-family: "Montserrat", sans-serif;  
  font-size: 15px;
  font-weight: 200;
  margin-top: 3%; 
}
.exhibition-location1 {
  margin-top: -3%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 500;
}
.exhibition-location2 {
  margin-top: -3%;
  margin-bottom: 4%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 500;
}

.bottom-div {
  margin-bottom: 30%;
}