
.music-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 45px;
  margin-top: 5%;
  margin-bottom: 3%;
  margin-left: -1%;
  padding-left: 0;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.music-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.car-photo-music {
  height: 180px;
  margin-bottom: 7%;
  margin-top: 2%;
  margin-left: 1%;
}

.hally-wood-title {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin-bottom: 1%;
  margin-top: 6%;
  font-size: 25px;
  font-weight: 600;
}

.music-main-container {
  height: 910px;
  width: 100%;
}

.music-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.hally-album-photo-left {
  height: 400px;
  margin-left: 3%;
}

.hally-album-photo-right {
  height: 400px;
  margin-left: 3%;
}

.annabel-playing-guitar-photo {
  height: 400px;
  margin-left: 3%;
}

.hally-wood-container {
  padding: 2%;
  width: 70%;

}

.benson-gallery-div-2pics {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 2%;
}


.link-to-hally-wood-song {
  text-decoration: underline;
  color: black;
}

.hally-wood-song {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin-bottom: 10%;
  margin-top: 5%;
  font-size: 17px;
  font-weight: 200;
  margin-left: -2%;
  text-decoration: underline;
}


.music-line-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.music-line-divider {
  height: 70px;
  opacity: .5;
}