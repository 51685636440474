.writing-samples-main-container {
  height: 910px;
  width: 100%;
  margin: 0 auto;
}
.writing-samples-car-photo {
  height: 115px;
  margin-bottom: 2%;
  margin-top: 2%;
}
.writing-samples-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  margin-top: 5%;
  padding-bottom: 3%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.writing-samples-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.writing-samples-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}


.writing-samples-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}

.writing-samples-main-div {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  padding-top: 3%;
}



.brown-harris-pages {
  margin-top: 5%;
  border-bottom: 1px solid black;
  padding-bottom: 5%;
  width: 100%;
}

.humbolts-gift-passage {
  margin-top: 5%;
  border-bottom: 1px solid black;
  padding-bottom: 5%;
  width: 100%;
}

.about-hudson-passage {
  margin-top: 5%;
  border-bottom: 1px solid black;
  padding-bottom: 5%;
  width: 80%;
}

.natural-collection-passage {
  margin-top: 1%;
}

.chatham-press-passage {
  margin-top: 3%;
  margin-bottom: 3%;
}



.donna-dennis-passage-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid black;
  padding-bottom: 5%;
  width: 98%;
}

.dennis-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.dennis-passage-gallery-link {
  text-decoration: none;
  color: black;
  border-bottom: 1px solid black;
}

.link-to-dennis-passage {
  text-decoration: none;
  color: black;
}

.dennis-passage-title {
  margin-bottom: 3%;
  font-size: 24px;
  margin-top: 7%;
}

.dennis-img-main {
  width: 65%;
  margin: 0 auto;
  margin-top: 4%;
  margin-bottom: 5%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.dennis-img-secondary {
  width: 65%;
  margin: 0 auto;
  margin-top: 2%;
  margin-bottom: 3%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.dennis-article-writing {
  text-align: justify;
  margin-left: 3%;
  margin-right: 3%;
}

.dennis-passage-article {
  font-style: italic;
  font-size: 17px;
}






.writing-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}
.writing-info-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 3%;
  width: 100%;
}
.writing-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: .4s ease-in-out;
  margin-bottom: 2%;
  width: 90%;
}



.chatham-press-img {
  width: 95%;
}

.natural-collection-img {
  width: 110%;
  margin-left: -4%;
}

.dunn-img {
  width: 95%;
}

.humbolts-gift-img {
  width: 95%;
}

.brown-harris-img {
  width: 95%;
}

.about-hudson-img {
  width: 95%;
}






.about-line-container-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0%;
}
.about-line-divider-bottom {
  height: 100px;
  opacity: .8;
}
.sequestered-film-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 30px;
  width: 100%;
  text-align: center;
  margin-bottom: 2%;
}
.sequestered-film-title-1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 28px;
  width: 100%;
  text-align: center;
  margin-top: 1%;
}
.sequestered-film-title-2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 2%;
  width: 100%;
  text-align: center;
}
.sequestered-video-container {
  margin-top: 1%;
  margin-bottom: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}
.dafoe-video-container {
  margin-top: 3%;
  margin-bottom: 5%;
}
.dafoe-film {
  margin: 0 auto;
}