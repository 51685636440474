@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

input[type=text],input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

.contact-main-container {
  margin-top: 1%;
  height: 700px;
}

.contact-form-container {
  width: 50%;
  margin: 0 auto;
}

.contact-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  margin-top: 5%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.contact-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.contact-description {
  color: rgb(78, 78, 78);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  text-align: center;
  margin-bottom: 3%;
  margin-top: 2%;
  font-weight: 200;
}

.social-container {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  padding-top: .5%;
}

.social-icons {
  padding: 0;
  list-style: none;
  margin: 1em;
}
.social-icons .li-social {
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 1.8em;
}
.social-icons i {
  color: #fff;
  position: absolute;
  top: 11.5px;
  left: 18.5px;
  transition: all 265ms ease-out;
}
.social-icons .a-social {
  display: inline-block;
}
.social-icons .a-social:before {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  content: " ";
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: block;
  background: -webkit-linear-gradient(45deg, rgb(0, 237, 245), #a5f5f1 );
  background: -moz-linear-gradient(45deg, rgb(0, 237, 245), #a5f5f1 );
  background: linear-gradient(45deg, rgb(0, 237, 245), #a5f5f1 );
  transition: all 265ms ease-out;
  border: 1px solid rgb(173, 173, 173);
}
.social-icons .a-social:hover:before {
  transform: scale(0);
  transition: all 265ms ease-in;
}
.social-icons .a-social:hover i {
  transform: scale(1.8);
  -ms-transform: scale(1.8);
  -webkit-transform: scale(1.8);
  color: #a5f5f1;
  background: -webkit-linear-gradient(45deg, #2df8ff, #a5f5f1 );
  background: -moz-linear-gradient(45deg, #2df8ff, #a5f5f1 );
  background: linear-gradient(45deg, #2df8ff, #a5f5f1 );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 265ms ease-in;
}
.fa {
  width: 44%;
}

.li-social {
  padding: 0 2% 0 2%;
}