.car-photo-vehicle-editions-1 {
  height: 150px;
  margin-bottom: 3%;
  margin-top: 2%;
}

.veh-ed-nav-info-container {
  width: 100%;
  margin-top: 3%;
}

.veh-ed-nav-container{
  width: 100%;
}
.veh-ed-nav-bullets-div{
  width: 100%;
}
.veh-ed-nav-ul{
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  padding: 0;
}
.veh-ed-nav-li { 
  margin: 1% 3% 1% 3%;
}
.veh-ed-nav-link {
  text-decoration: none !important;
  color: black;
  padding-bottom: 1px;
  padding-top: 1px;
  color: black !important;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.veh-ed-nav-li:hover {
  border-bottom: none;
  border-top: 6px solid black;
  margin-top: 7px;
}


.veh-ed-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 50px;
  margin-top: 5%;
  padding-bottom: 5%;
  color: rgba(0, 0, 0, .8);
  font-weight: 400;
  text-align: center;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}

.veh-ed-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}


#links-pages-style {
  position: relative;
  z-index: 1;
  display: inline-flex;
  padding-top: 3px;
  font-family: 'Open Sans', sans-serif;
}

/* 


#links-pages-style::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgba(255, 212, 95, 0.7) 15%, transparent 10%);
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: -1;
  will-change: width;
  transform-origin: left bottom
}
  
#links-pages-style:hover::before{
  width: 0;
}
  

  
#links-pages-style::before{
  transition: width .1s ease-out;
}

#links-pages-style:hover::before{
  transition-duration: .15s;
} */