/* box-shadow: 0px 1px 26px -8px rgba(0,0,0,.8);
 */

.testimonials-main-title {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  margin-top: 5%;
  margin-bottom: 2%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  width: 19%;
  text-align: center;
  border-bottom: 1px solid rgb(107, 107, 107);
}

.testimonials-info-container {
  width: 100%;
}
.testimonials-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-content: center;
  align-items: center;
}