.clients-main-container {
  height: 910px;
  width: 100%;
}

.clients-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}
.clients-car-photo {
  height: 115px;
  margin-bottom: 3%;
  margin-top: 2%;
}
.clients-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 42px;
  margin-top: 5%;
  margin-bottom: 3%;
  margin-left: -1%;
  padding-left: 0;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.clients-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}


.regular-clients-container {
  padding: 0 2% 2% 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
}
.clients-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.clients-info-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 3%;
}
.clients-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: .4s ease-in-out;
  margin-bottom: 2%;
}
.regular-clients-title-container {
  margin-top: 2%;
}
.regular-clients-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
}


.clients-car-photo-1 {
  height: 170px;
  margin-top: 6%;
  margin-bottom: 12%;
  box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
}
.clients-car-photo-2 {
  height: 200px;
  margin-top: 6%;
  margin-bottom: 12%;
  box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
}
.clients-car-photo-3 {
  height: 200px;
  margin-top: 6%;
  margin-bottom: 16%;
  box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
}
.clients-car-photo-4 {
  height: 200px;
  margin-top: 6%;
  margin-bottom: 13%;
  box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
}
.clients-car-photo-5 {
  height: 200px;
  margin-top: 6%;
  margin-bottom: 13%;
  box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
}
.clients-car-photo-6 {
  height: 180px;
  margin-top: 6%;
  margin-bottom: 13%;
  box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
}

.clients-line-divider {
  height: 70px;
  opacity: .5;
}

.last-client {
  margin-bottom: 15%;
}
/*
.clients-car-photo-1-div {
  margin: 5% 45% 30% 0;
}
.clients-car-photo-2-div {
  margin: 5% 0 30% 0;
}
.clients-car-photo-3-div {
  margin: 5% 45% 30% 0;
}
.clients-car-photo-4-div {
  margin: 5% 0 30% 0;
}
.clients-car-photo-5-div {
  margin: 5% 25% 5% 0;
}
.clients-car-photo-6-div {
  margin: 5% 0 5% 0;
} */

.client-pictures-div {
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -115%;
}

.clients-div-1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: .4s ease-in-out;
}

.clients-name {
  font-family: "Montserrat", sans-serif;  
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 10%;
}

.clients-div:hover {
  transform: scale(1.2);
}

.btn-modal:focus {
  outline: none !important;
}
#button-modal:focus {
  outline: none !important;
}

#button-modal {
  background: none !important;
  border: none !important;
}


/* ––––––––––––––––– */