.poems-main-container {
  height: 910px;
  width: 100%;
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
}
.car-photo-poems {
  height: 200px;
  margin-bottom: 2%;
  margin-top: -2%;
}

.poems-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.poems-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 45px;
  margin-top: 5%;
  margin-bottom: 4%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}

.poems-info-main-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  margin-top: 3%;
}

.poems-links-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}


.poems-dynamic-container {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  width: 40%;
}

.poems-links {
  margin-right: 5%;

}

.titles-of-poems-text {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  color: black;
  font-weight: 200;
  border-bottom: 1px solid black;
}



.poems-name {
  font-family: "Montserrat", sans-serif;  
  font-weight: 200;
  font-size: 20px;
}

#modal-body-images {
  display: flex;
  justify-content: center;
}

.poem-images {
  width: 98%;
}

.poem-img-night-and-night {
  width: 98%;
}

.poem-img-glamorous {
  width: 98%;
}

.poem-img-johnboy {
  width: 98%;
}

.poem-img-scenic-radios {
  width: 98%;
}

.poem-img-apoplectic-ride {
  width: 98%;
}

.poem-img-queen-maniac {
  width: 98%;
}

.poem-img-short-blade {
  width: 98%;
}

.poem-img-red-signal {
  width: 98%;
}

