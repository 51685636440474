.main-page-container {
  width: 100%;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
  /* background-image: url('https://i.imgur.com/CeJ8sZc.jpg'); */
  background-repeat: repeat; 
  background-size: cover;
  background-color: black;
}

.name-of-site {
  font-family: "Montserrat", sans-serif;
  border: 2px solid white;
  width: 25%;
  font-weight: 200;
  color: white;
}

.homepage-img {
  width: 100%;
}

/* 
.bottom-div {
  background-color: black;
  height: 0px;
} */
/* 
#link {
  text-decoration: none;
  margin: 0 auto;
}

.arrow {
  -webkit-animation: shake-vertical 18s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
  animation: shake-vertical 18s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
  80% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
  90% {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
  80% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
  90% {
        -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
}

 */


.testimonials-boxes-main-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 5%;
}
.testimonials-container-boxes {
  margin: 3%;
  padding: 7%;
  box-shadow: 0px 1px 26px -8px rgba(0,0,0,.4);
  width: 73%;
}
.testimonials-container-box-title {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  color: black;
  font-weight: 200;
}
.testimonials-container-box-text {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: black;
  font-weight: 200;
}