


.book-of-signs-main-container {
  margin-top: 12%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ilylali-link {
  color: black;
  text-decoration: underline;
  text-align: center;

}

.buy-now-button {
  border-radius: 40px;
  width: 20%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  font-family: "Montserrat", sans-serif;  
  font-size: 15px;
  border: 2px solid orangered;
  outline: none;
  margin-top: 3%;
  -webkit-box-shadow: 0px 1px 26px -5px rgba(0,0,0,0.41);
  -moz-box-shadow: 0px 1px 26px -5px rgba(0,0,0,0.41);
  box-shadow: 0px 1px 26px -5px rgba(0,0,0,0.41);
}

.buy-now-button:hover {
  background-color: white;
  color: black;
  border: rgb(172, 172, 172) solid 2px;
}


.signs-dynamic-div {
  display: flex;
  justify-content: center;
}


.text {
  /* font-family: 'Cutive Mono', monospace; */
  font-family: "Montserrat", sans-serif;  
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  margin-bottom: 2%;
}

.text-last {
  font-family: "Montserrat", sans-serif;  
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10%;

}

.bold-text {
  font-family: "Montserrat", sans-serif;  
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 7%;
}



.cta {
  text-decoration: none;
  color: inherit;
  position: relative;
  margin: auto;
  padding-top: 12px;
  padding-left: 4%;
  padding-right: 4%;
  transition: all 0.2s ease;
}


.svg-buy-now {
  margin-top: 4%;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: rgba(255, 171, 157, 0.5);
  width: 46px;
  height: 46px;
  transition: all 0.3s ease;
}
.cta span {
  position: relative;
  /* font-family: 'Cutive Mono', monospace; */
  font-size: 14px;
  line-height: 14px;
  font-weight: 900;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  vertical-align: middle;
}
.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #111;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}
.cta:hover:before {
  width: 100%;
  background: #ffab9d;
}
.cta:hover svg {
  transform: translateX(0);
}
.cta:active {
  transform: scale(0.96);
}