.about-ve-container-bottom {
  padding: 0% 2% 2% 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin:0 auto;
  padding-top: 6%;
  padding-bottom: 2%;
} 

.about-ve-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 45px;
  margin-top: 5%;
  padding-bottom: 1%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}