
#pro-serv-car-photo-1 {
  height: 145px;
  margin-bottom: 2%;
  margin-top: 4%;
}

.professional-services-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 50px;
  margin-top: 5%;
  padding-bottom: 1px;
  color: rgba(0, 0, 0, .8);
  font-weight: 400;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}


.professional-services-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.pro-serv-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  margin-top: 4%;
  margin-bottom: 2%;
}

.pro-serv-nav-ul{
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  padding: 0;
}
.pro-serv-nav-li { 
  margin: 1% 3% 1% 3%;
}