.writing-main-container {
  height: 910px;
  width: 100%;

}

.veh-ed-nav-bullets-div-writing {
  width: 100%;
  margin: 0 auto;
}
.writing-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}
.writing-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 50px;
  margin-top: 6%;
  padding-bottom: 1%;
  color: rgba(0, 0, 0, .8);
  font-weight: 400;
  text-align: center;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}

.writing-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.car-photo-writing {
  height: 240px;
  margin-top: -2%;
}

.car-pic-writing {
  display: flex;
  justify-content: center;
}

.writing-nav-ul{
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  padding: 0;
}
.writing-nav-li { 
  margin: 3% 5% 3% 5%;
}

.writing-nav-li:hover {
  border-bottom: none;
  border-top: 6px solid black;
  margin-top: 17px;
}