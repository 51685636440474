.anthologies-main-container {
  height: 910px;
  width: 100%;

}
.car-photo-anthologies {
  height: 200px;
  margin-bottom: 2%;
  margin-top: -2%;
}

.anthologies-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.anthologies-main-title {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  margin-top: 5%;
  color: black;
  font-weight: 200;
  /* border-top: 2px solid black;
  border-bottom: 2px solid black; */
  width: 45%;
  text-align: center;
}

.anthologies-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.anthologies-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.anthologies-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: -1%;
}