
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.about-component-main-container {
  height: 910px;
  width: 100%;
}

.about-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}
/* 
.about-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  margin-top: 5%;
  margin-bottom: 5%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  width: 25%;
  text-align: center;
} */

.about-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 45px;
  margin-top: 5%;
  padding-bottom: 5%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.about-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.about-container-top {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin:0 auto;
  padding-bottom: 2%;
} 

.about-container-bottom {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin:0 auto;
  padding-top: 6%;
  padding-bottom: 12%;
} 



.about-text-titles {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 2%;
  width: 100%;
}

.about-car-img-div {
  margin-right: 3%;
  margin-left: -2%;
  margin-top: 5%;
}
.car-photo-about {
  height: 150px;
}

.homepage-about-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
}
.homepage-about-container-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
}

.about-title {
  margin-bottom: 5%;
  margin-top: 5%;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  color: black;
  font-weight: 500;
}

.about-text-container {
  text-align: left;
  width: 100%;
}

.about-about-text {
  width: 100%;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
}

.about-img-container {
  width: 40%;
  margin-top: 5%;
}
.about-profile-img {
  height: 220px;
  border-radius: 100%;
  border: 2px solid black;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
}

