.available-titles-main-container {
  height: 910px;
  width: 100%;

}
.car-photo-vehicle-editions-available {
  height: 110px;
  margin-top: 2%;
  margin-bottom: 7%;
}

.available-titles-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.available-titles-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 42px;
  margin-top: 5%;
  margin-bottom: 3%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.available-titles-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}


.available-titles-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.available-titles-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 70%;
}

.available-titles-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.available-titles-info-div-signs {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.available-titles-info-div-rudy {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin-bottom: 10%;
  width: 70%;
}


/* .available-titles-link-container {

} */

.available-titles-signs-name {
  font-family: "Montserrat", sans-serif;  
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 20%; 
  text-decoration: none;
  color: black;
  text-decoration: underline;
}

.available-titles-rudy-name {
  font-family: "Montserrat", sans-serif;  
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 3%; 
  text-decoration: none;
  color: black;
  text-decoration: none;
  text-align: center;
}


.train-ride-main-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  margin-top: 2%;
}

.book-of-signs-img {
  height: 330px;
  margin: 0 auto;
}

.rudy-images-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.convo-with-rudy-img {
  height: 300px;
}
.convo-with-rudy-2-img {
  height: 300px;
}

.typings-img {
  height: 300px;
  margin-left: 2%;
}

.train-ride-available-img {
  height: 300px;
}
.train-ride-available-img-alt {
  height: 500px;
  margin: 3%;
}

.train-ride-modal-img {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.towle-available-img {
  height: 400px;
  padding-left: 4%;
}

.available-img {
  height: 400px;
}




.tony-video {
  margin-right: 0;
  display: flex;
  justify-content: right;
  align-content: right;
  align-items: right;
}

.typings-2-img {
  height: 300px;
}



.signs-dynamic-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rudy-dynamic-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}


.rudy-photos-description1 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 4%;
}

.rudy-photos-description2 {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-style: italic;
  margin-top: -1%;
  text-align: center;
}

.rudy-conversations-link {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.rudy-conversations-link-2 {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}



.av-titles-div-modal {
  font-family: "Montserrat", sans-serif;  
  font-weight: 800;
  font-size: 17px;
  text-decoration: underline;
}


.avail-info-main-container-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 3%;
}

.train-modal-text {
  text-align: center;
}


.train-modal-div-link {
  width: 100%;
  display: flex;
  justify-content: center;
}

.train-modal-text-link {
  text-align: center;
}


.rudy-author-name-2 {
  margin-bottom: 5%;
  margin-top: -3%;
  font-weight: 800;
  text-align: center;
  font-size: 23px;
}

.typings-text-price {
  font-family: "Montserrat", sans-serif;  
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 2%;
}
.typings-text {
  font-family: "Montserrat", sans-serif;  
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  margin-bottom: -2%;
}

.typings-text-last {
  font-family: "Montserrat", sans-serif;  
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  margin-bottom: 4%;
}

.typings-bold-text {
  font-family: "Montserrat", sans-serif;  
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 3%;
}

.tony-text {
  font-family: "Montserrat", sans-serif;  
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  margin-bottom: 1%;
}

.tony-text-last {
  font-family: "Montserrat", sans-serif;  
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  margin-bottom: 4%;
}

.tony-bold-text {
  font-family: "Montserrat", sans-serif;  
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 3%;
}

.buy-now-div2 {
  width: 22%;
  display: flex;
  justify-content: center;
}

.buy-now-div1 {
  width: 22%;
  display: flex;
  justify-content: center;
}

.buy-now-div3 {
  width: 30%;
  display: flex;
  justify-content: center;
  margin-top: -1% !important;
  margin-bottom: 5% !important;
  margin-left: 0% !important;
}

.buy-now-div4 {
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  margin-top: 3% !important;
  margin-bottom: 5% !important;
  margin-left: 0% !important;
}

.buy-now-div5 {
  width: 22%;
  display: flex;
  justify-content: center;
  margin-top: 2%;
}



/* .paypal-button {
  padding: 7px 20px 7px 20px;
  background-color: rgb(204,104,102);
  color: white;
  border: none;
  border-radius: 600px;
  font-size: 20px;
  box-shadow: 0px 1px 16px -2px rgba(0,0,0,0.21);
  transition: 500ms;
}


.paypal-button:hover {
  transition: 500ms;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.21);
  background-color: #cc6695;
} */


.train-ride-cover-text {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-top: 2%;
  font-style: italic;
}

.train-ride-text {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 1%;
}

.train-ride-text-price {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.minnesota-button-div {
  margin-top: 2%;
  margin-bottom: 2%;
}

.minnesota-button {
  padding: 10px 22px 10px 22px;
  background-color: rgb(204,104,102);
  color: white;
  border: none;
  border-radius: 600px;
  font-size: 20px;
  box-shadow: 0px 1px 16px -2px rgba(0,0,0,0.21);
  transition: 500ms;
}

.minnesota-button:hover {
  transition: 500ms;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.21);
  background-color: #cc6695;
  color: white;
}
