.pub-consulting-main-container {
  height: 910px;
  width: 100%;
}
.pub-consulting-car-photo {
  height: 115px;
  margin-bottom: 2%;
  margin-top: 2%;
}

.pub-consulting-title-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.pub-consulting-main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 42px;
  margin-top: 6%;
  padding-bottom: 4%;
  color: rgba(0, 0, 0, .8);
  font-weight: 200;
  text-align: center;
  text-shadow: 3px 3px 2px rgba(113, 209, 189, .5);
  letter-spacing: 2px;
  word-spacing: 3px;
  transition: 500ms;
}
.pub-consulting-main-title:hover {
  transition: 500ms;
  text-shadow: 3px 3px 0px rgba(113, 209, 189, 1);
}

.pub-consulting-info-and-pic-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 5%;
  margin: 0 auto;
}


.pub-consulting-bullets-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 70%;
  margin-bottom: 6%;
}

.pub-consulting-ul {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 3%;
  width: 100%;
}

.pub-consulting-li {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 16px;
  margin-top: 7%;
  list-style-type: none;
  margin-right: 5%;
  padding: 1% 2% 1% 2%;
  border-radius: 50px;
  -webkit-animation: text-shadow-drop-center  1s .8s both;
  animation: text-shadow-drop-center  1s .8s both;
  transition: .4s ease-in-out;
  text-align: center;
}

.pub-consulting-li:hover {
  transform: scale(1.2);
}

.pub-consulting-blurb {
  margin-top: -2%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 200;
  font-style: italic;
  margin-bottom: 8%;
}



@-webkit-keyframes text-shadow-drop-center {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  }
  100% {
    box-shadow: 0px 1px 26px -8px rgba(0,0,0,0.41);
  }
}
@keyframes text-shadow-drop-center {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  }
  100% {
    box-shadow: 0px 1px 26px -8px rgba(0,0,0,0.41);
  }
}






